import React from "react"

const PrivacyPage = () => {
  return (
    <div>
      <h1>How we use and store your Data</h1>
      <h2>Introduction</h2>
      <p>
        This document explains how we use and store any information that you
        send through the contact channels listed on the website
        www.capitalcardrewards.com.
      </p>
      <h2>How we collect your information</h2>
      <p>
        We do not collect any information from you unless you contact us via
        email.
      </p>
      <p>
        When sending an email to us, we will collect your email address and any
        other details you send. By sending us information through the
        communication channels you agree to how we use it as described below.
      </p>
      <h2>What we do with it and how long we keep it</h2>
      <p>
        We only use/store information for the purpose of resolving your enquiry.
        This may include forwarding your email to other WDP staff. We will not
        share your information with any third parties unless we have a statutory
        requirement to do so.
      </p>
      <p>
        Once enquiries are resolved your email will be deleted after 3 days
        unless we have a statutory requirement to hold it for longer. If you do
        not hear back from us, your information will be stored for a maximum of
        3 days before being deleted.
      </p>
      <h2>Where we store it and how we delete it</h2>
      <p>
        Any information shall be stored on WDP’s secure IT network which is
        protected and monitored.
      </p>
      <p>
        We securely delete your information from our systems in a way than
        cannot be retrieved.
      </p>
      <h2>How you can contact us</h2>
      <p>Our Head office is:</p>
      <blockquote>
        WDP 18 Dartmouth Street London, SW1H 9BL Tel: 020 7421 3100
      </blockquote>
      <strong>Email:</strong> enquiries@wdp.org.uk
    </div>
  )
}

export default PrivacyPage;